<template>
  <v-breadcrumbs density="compact" :items="items" class="breadcrumbs">
    <template #divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
    <template #item="{ item }">
      <v-breadcrumbs-item class="text-accent font-weight-bold">
        {{ item.title }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface BreadcrumbItem {
  title: string;
}

const props = defineProps<{
  path: string[],
  newItem?: string,
}>();

const items = computed((): BreadcrumbItem[] => {
  const breadcrumbs: BreadcrumbItem[] = props.path.map((p) => ({ title: p }));
  if (props.newItem) {
    breadcrumbs.push({ title: props.newItem });
  }
  return breadcrumbs;
});

</script>

<style scoped>
.breadcrumbs {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
