<template>
  <v-dialog v-model="model" max-width="1200px">
    <v-card class="pa-5" v-if="model">
      <template #title>
        <div color="primary" class="ma-0 pt-1">
          <h2 class="ma-0 text-uppercase">{{ question.title }}</h2>
        </div>
      </template>
      <template #append>
        <v-btn @click="model = false" icon="mdi-close" />
      </template>
      <v-card-text>
        <div class="text-right text-caption">
          <span>Valideringsregler: <code>{{ question.validation?.map((rule) => rule.rule) || "Ingen" }}</code></span> |
          <span>Backend nøgle: <code>{{ question.key || "Ingen" }}</code></span> |
          <span>Visning: <code>{{ question.display || "Normal" }}</code></span> |
          <span>Kundevisning: <code>{{ question.accessibleForCustomer ? "Vises" : "Vises ikke" }}</code></span> |
          <span>Leverandøradgang: <code>{{ question.accessForSupplier }}</code></span>
        </div>
        <div>
          <v-text-field
            v-model="question.title"
            :disabled="true"
            variant="outlined"
            density="compact"
            label="Titel"
            class="mt-5"
          />
          <v-textarea
            v-if="!root"
            v-model="question.helpText"
            :disabled="true"
            variant="outlined"
            density="compact"
            label="Info tekst (tooltip)"
          />
          <div v-html="text" class="mb-5" />
          <VariantForm v-if="question.questionType === 'Section'" :value="question" :question="question" :readonly="true" />
          <MandatoryAnswerSelector
            v-if="
              question.questionType === 'Boolean'
                || (question.questionType === 'Options'
                  && canBeConvertedToBoolQuestion)
            "
            :value="mandatoryAnswerValue"
            :readonly="true"
          />
          <DependenciesDisplay
            v-if="!root"
            :locator="locator"
            :condition="question.dependsOn"
            :readonly="true"
            :currentQuestion="question"
          />
          <OptionsEditor
            v-if="question.questionType === 'Options'"
            :question="question"
            :readonly="true"
          />
          <FileSet
            v-if="question.questionType === 'FileDownload'"
            :editing="true"
            :template="template"
            :question="question"
            :readonly="true"
          />
          <PricePointSet
            v-if="question.questionType === 'PricePointSet'"
            :question="question"
            :questions="questions"
            :template="template"
            :readonly="true"
          />
          <NumericInputEditor
            v-if="question.questionType === 'Number'"
            :question="question"
            :readonly="true"
          />
          <IntervalsEditor
            v-if="question.questionType === 'Intervals'"
            :question="question"
            :readonly="true"
          />
          <v-select
            v-if="question.questionType === 'Placeholder'"
            v-model="questionPlaceholderVariant"
            :items="placeholderVariants"
            variant="outlined"
            label="Placeholder type"
            :readonly="true"
          ></v-select>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import {
  DeepQuestionnaireTemplate,
  QuestionDefinition,
  PlaceholderVariantType,
  placeholderVariantTypeList,
} from '@dims/components';
import { computed } from 'vue';
import FileSet from '@/components/shared/FileSet.vue';
import {
  isOption,
} from '@/components/Templates/utilities';
import OptionsEditor from './QuestionForm/OptionsEditor.vue';
import VariantForm from './QuestionForm/VariantForm.vue';
import MandatoryAnswerSelector from './QuestionForm/MandatoryAnswerSelector.vue';
import DependenciesDisplay from './QuestionForm/DependenciesDisplay.vue';
import PricePointSet from './QuestionForm/PricePointSet/PricePointSet.vue';
import IntervalsEditor from './QuestionForm/IntervalsEditor.vue';
import QuestionLocator from './QuestionLocator';
import NumericInputEditor from './QuestionForm/NumericInputEditor.vue';

/** The popup dialog displaying a question in readonly-mode */
const { template, question, root = false, locator } = defineProps<{
  template: DeepQuestionnaireTemplate,
  question: QuestionDefinition,
  root?: boolean,
  locator: QuestionLocator }>();
const model = defineModel<boolean>();

const questions = computed(() => template.questions);

const text = computed(() => question.text);

const questionPlaceholderVariant = computed({
  get() {
    return question.variant as PlaceholderVariantType;
  },
  set(placeholderVariant: PlaceholderVariantType) {
    question.variant = placeholderVariant;
  },
});

const canBeConvertedToBoolQuestion = computed(() => {
  if (isOption(question)) {
    const result = question.options?.length === 2
        && question.options.every(
          (option) => option.text === 'Ja' || option.text === 'Nej',
        );
    return result;
  }
  return false;
});

const mandatoryAnswerValue = computed(() => canBeConvertedToBoolQuestion.value);

const placeholderVariants = computed((): PlaceholderVariantType[] => placeholderVariantTypeList.map((element) => element));

</script>
