<template>
  <v-data-table
    class="elevation-1 my-5"
    :headers="headers"
    :items="items"
    no-data-text="Ingen afkrydsningsfelter"
    no-results-text="Ingen afkrydsningsfelter"
    :items-per-page-options="[5, 10]"
  >
    <template #top>
      <v-toolbar flat color="canvas">
        Afkrydsningsfelt
        <v-chip v-if="items" class="mx-2" size="small">{{ items.length }}</v-chip>
        <v-row class="align-center ml-2">
          <v-col>
            <v-text-field
              v-model="text"
              label="Indsæt tekst"
              hide-details
              variant="outlined"
              density="compact"
              :disabled="readonly"
            ></v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-btn
              @click="addItem()"
              class="primary-button-mini"
              :disabled="!text || readonly"
            >Tilføj</v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template #[`item.actions`]="{ item, index }">
      <v-btn @click="deleteItem(item)" class="float-right" :disabled="readonly" icon="mdi-delete" />
      <v-btn
        @click="openEditItem(item)"
        class="float-right"
        :disabled="readonly"
        icon="mdi-pencil"
      />
      <v-btn
        variant="text"
        @click="moveUp(index)"
        class="float-right"
        :disabled="(index === 0) || readonly"
        icon="mdi-arrow-up"
      />
      <v-btn
        variant="text"
        @click="moveDown(index)"
        class="float-right"
        :disabled="(index === items.length - 1) || readonly"
        icon="mdi-arrow-down"
      />
    </template>
  </v-data-table>
  <v-dialog v-if="editingCell" v-model="showEditDialog" max-width="600px">
    <v-card>
      <v-card-title>Rediger valgmulighed</v-card-title>
      <v-card-text>
        <v-text-field v-model="editingCell.title" label="Tekst" />
        <!-- <v-text-field v-model="editingCell.optionId" label="ID" /> -->
      </v-card-text>
      <v-card-actions>
        <v-btn @click="closeEditItem">Annuller</v-btn>
        <v-btn @click="editItem">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { QuestionDefinition, SectionQuestionDefinition } from '@dims/components';
import { computed, ref } from 'vue';
import { v4 as newUUID } from 'uuid';

interface CheckboxItem { id: string; title: string }

const { question, readonly = false } = defineProps<{
  question: SectionQuestionDefinition,
  readonly?: boolean
}>();

const editingCell = ref<CheckboxItem | null>(null);
const showEditDialog = ref(false);

const items = computed((): CheckboxItem[] => (question.questions ?? []).map((q) => ({ title: q.title, id: q.questionDefinitionId })));

const text = ref('');
const headers = [
  {
    title: 'Titel',
    value: 'title',
  },
  {
    title: 'ID',
    value: 'id',
  },
  {
    title: '',
    value: 'actions',
  },
];

const questions = computed(() => question.questions ?? []);

function addItem() {
  const id = newUUID();
  questions.value.push({
    questionDefinitionId: id,
    name: id,
    questionType: 'Boolean',
    variant: 'checkbox-list',
    title: text.value,
    text: '',
  });
  text.value = '';
}

function deleteItem(item: CheckboxItem) {
  const questionsList = question.questions ?? [];
  question.questions = [...questionsList.filter((q) => q.questionDefinitionId !== item.id)];
}

function openEditItem(item: CheckboxItem) {
  editingCell.value = item;
  showEditDialog.value = true;
}

function editItem() {
  if (editingCell.value) {
    question.questions = questions.value.map((q) => {
      if (q.questionDefinitionId === editingCell.value?.id) {
        return {
          ...q,
          title: editingCell.value.title,
        };
      }
      return q;
    });
  }
  closeEditItem();
}

function closeEditItem() {
  editingCell.value = null;
  showEditDialog.value = false;
}

const moveUp = (index: number) => {
  if (index > 0) {
    const temp = { ...questions.value[index] };
    const previous = { ...questions.value[index - 1] };
    questions.value[index] = previous as QuestionDefinition;
    questions.value[index - 1] = temp as QuestionDefinition;
    question.questions = questions.value;
  }
};

const moveDown = (index: number) => {
  if (index < questions.value.length - 1) {
    const temp = { ...questions.value[index] };
    const next = { ...questions.value[index + 1] };
    questions.value[index] = next as QuestionDefinition;
    questions.value[index + 1] = temp as QuestionDefinition;
    question.questions = questions.value;
  }
};

</script>
