<template>
  <v-dialog v-model="dialog" max-width="1400">
    <template #activator="{ props }">
      <v-btn v-bind="props" icon="mdi-pencil" title="Rediger betingelser" />
    </template>
    <v-card class="pa-3">
      <template #title>
        <h3 class="mt-0 mb-4">Betingelser</h3>
      </template>
      <v-card-text>
        <v-radio-group
          v-if="selected && selected.length > 1"
          v-model="operator"
        >
          <v-radio value="Or" label="En af" />
          <v-radio value="And" label="Alle" />
        </v-radio-group>
        <v-chip
          v-for="s in selectedItems"
          :key="s.questionDefinitionId"
          @click:close="remove(s)"
          closable
          class="ma-1"
          :title="s.displayPath"
        >
          {{ s.displayTitle }}
        </v-chip>
        <div v-if="selected.length > 0" class="ma-2">
          Spørgsmålet vises hvis følgende er valgt:
          <span>
            <b>{{ conditionDescription }}</b>
          </span>
        </div>
        <v-text-field
          v-model="search"
          append-inner-icon="mdi-magnify"
          label="Søg"
          single-line
          variant="outlined"
          density="compact"
          flat
          clearable
          clear-icon="mdi-close-circle-outline"
          hide-details
          class="mx-2 my-2"
        ></v-text-field>
        <v-data-table
          dense
          v-model="selected"
          :headers="headers"
          :items="dependencies"
          item-value="questionDefinitionId"
          :search="search"
          :group-by="[{ key: 'rootTitle' }]"
          :items-per-page-options="[10, 20, 50, -1]"
          show-select
          :custom-filter="customSearch"
          class="elevation-1 mt-6"
        >
          <template #group-header="{ item, columns, toggleGroup, isGroupOpen }">
            <tr>
              <td :colspan="columns.length">
                <v-btn
                  :icon="isGroupOpen(item) ? '$expand' : '$next'"
                  size="small"
                  variant="text"
                  @click="toggleGroup(item)"
                ></v-btn>
                {{ item.value }}
              </td>
            </tr>
          </template>
          <template #[`header.data-table-group`]></template>
          <template #[`header.data-table-select`]></template>
          <template #item.displayTitle="{ item }">
            <td :title="item.displayPath">{{ item.displayTitle }}</td>
          </template>
          <template #item.toc="{ item }">
            <td>{{ `${item.toc}${item.index ? `.${item.index}` : ''}` }}</td>
          </template>
          <template #item.option="{ item }">
            <td><v-chip size="small" :class="item.option ? 'bg-primary' : 'bg-deep-purple'">{{ labelForQuestionType(item.question.questionType) }}</v-chip></td>
          </template>
          <template #item.questionDefinitionId="{ item }">
            <td>{{ item.questionDefinitionId }}</td>
          </template>
          <template #item.question="{ item }">
            <td>
              <SimpleInfoDialog :title="item.displayTitle">
                <div>Titel</div>
                <v-sheet color="canvas" class="mb-4 pa-4">
                  <div>{{ item.question.title }}</div>
                </v-sheet>
                <div>ID</div>
                <v-sheet color="canvas" class="mb-4 pa-4">
                  <div>{{ item.question.questionDefinitionId }} <CopyToClipboard :text="item.question.questionDefinitionId" /></div>
                </v-sheet>
                <div>Tekst</div>
                <v-sheet color="canvas" class="mb-4 pa-4">
                  <div v-html="item.question.text"></div>
                </v-sheet>
                <div>Type</div>
                <v-sheet color="canvas" class="mb-4 pa-4">
                  <div>{{ labelForQuestionType(item.question.questionType) }}</div>
                </v-sheet>
                <div>Sti</div>
                <v-sheet color="canvas" class="mb-4 pa-4">
                  <div>{{ item.displayPath }}</div>
                </v-sheet>
                <template v-if="showOptions(item.question)">
                  <div>Valgmuligheder</div>
                  <v-sheet color="canvas" class="mb-4 pa-4">
                    <div v-for="o in item.question.options" :key="o.optionId">
                      {{ o.text }}
                    </div>
                  </v-sheet>
                </template>
              </SimpleInfoDialog>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="secondary-button" @click="dialog = false">
          Annuller
        </v-btn>
        <v-btn class="primary-button" @click="save()"> Gem </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { Condition, OptionsQuestionDefinition, QuestionDefinition, QuestionTypeName, QuestionTypesEnum, SimpleInfoDialog } from '@dims/components';
import { ref, computed, watch } from 'vue';
import QuestionLocator, { DependencyViewItem } from '../QuestionLocator';
import { labelForQuestionTypeEnum } from '@/models';
import CopyToClipboard from '@/components/shared/CopyToClipboard.vue';

type Operator = 'And' | 'Or';

const emit = defineEmits<{addDependencies: [selectedItems: DependencyViewItem[], operator: Operator]}>();
const { condition, locator, currentQuestion = null } = defineProps<{
  condition: Condition | undefined,
  locator: QuestionLocator,
  currentQuestion?: QuestionDefinition | null }>();
const dialog = ref(false);
const headers = [
  {
    title: 'Titel',
    value: 'displayTitle',
    sort: (a: string, b: string) => (a > b ? -1 : 1),
  },
  {
    title: 'TOC',
    value: 'toc',
    sortable: false,
  },
  {
    title: 'Type',
    value: 'option',
  },
  {
    title: 'ID',
    value: 'questionDefinitionId',
  },
  {
    title: 'Vis',
    value: 'question',
    sortable: false,
  },
];
const selected = ref<string[]>([]);
const operator = ref<Operator>('Or');
const search = ref('');

const dependencies = computed(() => locator.dependencyDisplayList
  // first reduce to only dependencies that come *before* the current question - you cannot make a dependency on something that comes later
  .reduce<{result: DependencyViewItem[], stop: boolean}>((acc, item) => {
    console.log(item.question.questionDefinitionId, currentQuestion?.questionDefinitionId);
    if (item.question.questionDefinitionId === currentQuestion?.questionDefinitionId) {
      acc.stop = true;
    }
    if (!acc.stop) {
      acc.result.push(item);
    }
    return acc;
  }, { result: [], stop: false }).result
  // then filter out some question types and variants that does not make sense as dependencies
  .flatMap((dep) => {
    if (currentQuestion) {
      if (currentQuestion.questionType === QuestionTypesEnum.OPTIONS
        && currentQuestion.options?.some((o) => o.optionId === dep.questionDefinitionId)) {
        return [];
      }
    }
    if (dep.question.variant === 'override-texts') {
      return [];
    }
    return dep;
  }));

const selectedItems = computed(() => dependencies.value.filter((x) => selected.value.some((d) => d === x.questionDefinitionId)));

watch(
  dialog,
  () => {
    if (!dialog.value) {
      dialog.value = false;
      selected.value = [];
    }
    if (condition && condition.operands.length > 0) {
      operator.value = condition.operator;
      selected.value = dependencies.value
        .filter((x) => condition.operands.includes(x.questionDefinitionId))
        .map((x) => x.questionDefinitionId);
    } else {
      selected.value = [];
    }
  },
);

function customSearch(_id: string, searchStr: string, item?: { raw: DependencyViewItem }): boolean {
  const text = searchStr.trim().toLowerCase();
  if (item?.raw) {
    return (item.raw.displayTitle.toLowerCase().includes(text)
    || (item.raw.displayPath?.toLowerCase().includes(text))
    || (!!item.raw.questionDefinitionId && text.includes(item.raw.questionDefinitionId)));
  }
  return false;
}

function remove(question: DependencyViewItem) {
  selected.value = selected.value.filter(
    (s) => s !== question.questionDefinitionId,
  );
}

function save() {
  emit('addDependencies', selectedItems.value, operator.value);
  dialog.value = false;
}

const conditionDescription = computed(() => {
  const operatorDkText = operator.value === 'And' ? 'OG' : 'ELLER';
  return selectedItems.value
    .map((x) => `${x.toc}${x.index ? `.${x.index}` : ''}`)
    .sort((a, b) => (a > b ? 1 : -1))
    .join(` ${operatorDkText} `);
});

function labelForQuestionType(type: QuestionTypeName) {
  return labelForQuestionTypeEnum(type);
}

function showOptions(question: QuestionDefinition): question is OptionsQuestionDefinition {
  return question.questionType === QuestionTypesEnum.OPTIONS;
}

</script>
