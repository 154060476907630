<template>
  <h1>Tools</h1>
  <v-card v-for="tool in tools" :key="tool.key" width="400" class="my-4">
    <v-card-title>
      {{ tool.name }}
    </v-card-title>
    <v-card-text>
      {{ tool.description }}
    </v-card-text>
    <v-card-action>
      <ArrowButton @click="execute(tool.key)">Execute</ArrowButton>
    </v-card-action>
  </v-card>
  <pre class="console">
    <div v-for="(line, ix) in log" :key="ix">{{ line }}</div>
  </pre>
</template>

<script lang="ts" setup>
import { ArrowButton, useBackend } from '@dims/components';
import { ref } from 'vue';

interface Tool {
  key: string;
  name: string;
  description: string;
}

const api = useBackend().getApi();
const tools = ref<Tool[]>();
const log = ref<string[]>([]);
async function load() {
  tools.value = (await api.get<Tool[]>('design-time/operations')).data;
}
void load();

async function execute(toolKey: string) {
  log.value.push('Executing...');
  const msg = (await api.post<string[]>(`design-time/operations/${toolKey}`)).data;
  log.value.push(...msg);
}

</script>
<style scoped>
.console {
  padding: 4px;
  width: 40em;
  font-family: monospace;
  background-color: black;
  color: white;
}
</style>
