<template>
  <!-- dialog is eager so we can get ref to iframe on display -->
  <v-dialog v-model="showDialog" eager max-height="90%" max-width="75vw">
    <v-card :title="title">
      <template #append>
        <v-btn icon="mdi-close" @click="showDialog = false" />
      </template>
      <v-card-text>
        <v-progress-circular v-if="loadingPreview" indeterminate />
        <iframe
          style="height: 70vh; width: 70vw"
          ref="iframePreview"
          title="Document preview"
        ></iframe>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">

import { ref, watch, onMounted, useTemplateRef } from 'vue';

const { title, html = null } = defineProps<{ title: string, html?: string | null }>();
const showDialog = ref(false);
const loadingPreview = ref(false);

watch(
  () => html,
  (newHtml: string | null) => {
    if (!newHtml || newHtml === '') {
      showDialog.value = false;
    } else {
      showDialog.value = true;
      displayHtml(newHtml);
    }
  },
);

onMounted(() => {
  if (html && html !== '') {
    displayHtml(html);
  }
});

const iframePreview = useTemplateRef<HTMLIFrameElement>('iframePreview');

function displayHtml(html1: string) {
  if (iframePreview.value) {
    iframePreview.value.srcdoc = html1;
  }
}

</script>
<style scoped>
.header {
  font-size: 28px;
}
</style>
