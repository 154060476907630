<!-- Display a template in read-only mode, typically for displaying an active or historical template -->
<template>
  <div>ID: {{ template.id }}</div>
  <div v-if="changes.length === 0">⚠️ Data om ændringer ikke tilgængelige ⚠️</div>
  <v-tabs v-model="tab">
    <v-tab value="Questionnaire">Formular</v-tab>
    <v-tab value="WordTemplates">Word-skabeloner</v-tab>
  </v-tabs>
  <v-tabs-window v-model="tab">
    <v-tabs-window-item value="Questionnaire">
      <div class="d-flex justify-end align-center">
        <v-switch
          v-model="showQuestionTypes"
          class="mx-4"
          label="Vis typer"
          density="compact"
          hide-details
          color="primary"
          inset
        ></v-switch>
        <v-switch
          v-model="showIds"
          class="mx-4"
          label="Vis ID'er"
          density="compact"
          hide-details
          color="primary"
          inset
        ></v-switch>
        <v-switch
          v-model="showDependencies"
          class="mx-4"
          label="Vis betingelser"
          density="compact"
          hide-details
          color="primary"
          inset
        ></v-switch>
      </div>
      <div class="d-flex justify-end align-center">
        <v-btn @click="collapse" variant="text" color="accent">Fold ind</v-btn>|
        <v-btn @click="expand" variant="text" color="accent">Fold ud</v-btn>
      </div>
      <TemplateTreeView
        v-if="template"
        :template="template"
        :templateChanges="changes"
        :questions="template.questions"
        :locator="locator"
        :expandedStateKeeper="expandedStateKeeper"
        :readonly="true"
        :showIds="showIds"
        :showQuestionTypes="showQuestionTypes"
        :showDependencies="showDependencies"
        :isLoading="isLoading"
        @viewQuestion="viewQuestion"
        @questionChanges="questionChanges"
        @questionsDeleted="questionsDeleted"
      />
      <QuestionDisplayDialog
        v-if="selectedQuestion"
        v-model="showQuestionDialog"
        :question="selectedQuestion"
        :template="template"
        :locator="locator"
      />
      <QuestionChangesDialog
        v-if="selectedQuestion"
        v-model="showQuestionChangesDialog"
        :question="selectedQuestion"
        :templateChanges="changes"
      />
      <QuestionsDeletedDialog
        v-if="selectedQuestion"
        v-model="showQuestionsDeletedDialog"
        :question="selectedQuestion"
        :templateChanges="changes"
      />
    </v-tabs-window-item>
    <v-tabs-window-item value="WordTemplates">
      <WordTemplatesPreview :template :showIds="false" :locator />
    </v-tabs-window-item>
  </v-tabs-window>
</template>

<script setup lang="ts">
import { DeepQuestionnaireTemplate, QuestionDefinition } from '@dims/components';
import { ref } from 'vue';
import ExpandedStateKeeper from '@/components/Templates/Editor/ExpandedStateKeeper';
import QuestionLocator from '@/components/Templates/Editor/QuestionLocator';
import TemplateTreeView from '@/components/Templates/Editor/TemplateTreeView.vue';
import QuestionDisplayDialog from '@/components/Templates/Editor/QuestionDisplayDialog.vue';
import WordTemplatesPreview from '../WordTemplatesPreview.vue';
import { QuestionDefinitionChange } from '../DeepQuestionnaireTemplateWithChanges';
import QuestionChangesDialog from '@/components/Templates/Editor/QuestionChangesDialog.vue';
import QuestionsDeletedDialog from '@/components/Templates/Editor/QuestionsDeletedDialog.vue';

const { template, changes } = defineProps<{ template: DeepQuestionnaireTemplate, changes: QuestionDefinitionChange[] }>();
const isLoading = ref(false);
const showQuestionDialog = ref(false);
const showQuestionChangesDialog = ref(false);
const showQuestionsDeletedDialog = ref(false);
const selectedQuestion = ref<QuestionDefinition | null>(null);
const showIds = ref(false);
const showQuestionTypes = ref(true);
const showDependencies = ref(false);

const tab = ref<'Questionnaire' | 'WordTemplates'>('Questionnaire');

const locator = new QuestionLocator(template);

const expandedStateKeeper = ref<ExpandedStateKeeper>(new ExpandedStateKeeper(template.questions, false));

function viewQuestion(question: QuestionDefinition) {
  selectedQuestion.value = question;
  showQuestionDialog.value = true;
}

function questionChanges(question: QuestionDefinition) {
  selectedQuestion.value = question;
  showQuestionChangesDialog.value = true;
}

function questionsDeleted(question: QuestionDefinition) {
  selectedQuestion.value = question;
  showQuestionsDeletedDialog.value = true;
}

function expand() {
  expandedStateKeeper.value.setAll(true);
}

function collapse() {
  expandedStateKeeper.value.setAll(false);
}

</script>
