import { QuestionDefinition } from '@dims/components';
import { createNumberInput } from './QuestionBuilder';

// eslint-disable-next-line import/prefer-default-export
export function createQuantityDiscountQuestions(): QuestionDefinition[] {
  const discountPctStep1Number = createNumberInput('Rabatsats for rabattrin 1 (procent)', { });
  discountPctStep1Number.defaultValue = '0';
  discountPctStep1Number.accessibleForCustomer = false;
  discountPctStep1Number.accessForSupplier = 'ReadWrite';
  const discountPctStep2Number = createNumberInput('Rabatsats for rabattrin 2 (procent)', { });
  discountPctStep2Number.defaultValue = '0';
  discountPctStep2Number.accessibleForCustomer = false;
  discountPctStep2Number.accessForSupplier = 'ReadWrite';
  return [discountPctStep1Number, discountPctStep2Number];
}
